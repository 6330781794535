<template>
  <div
    :class="[
      'text-default-input',
      isBordered ? 'text-default-input--bordered' : '',
      isAverage ? 'text-default-input--average' : '',
      disabled ? 'text-default-input--disabled' : '',
      readonly ? 'text-default-input--readonly' : '',
    ]"
  >
    <label :for="label">
      <input
        v-if="onChange"
        :id="label"
        @change="(e) => onChange(e.target.value)"
        :type="type"
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :maxlength="maxLength"
        :readonly="readonly"
      />
      <input
        v-else
        :id="label"
        v-model="setValue[id]"
        :type="type"
        :placeholder="placeholder"
        :disabled="disabled"
        :maxlength="maxLength"
        :readonly="readonly"
      />
    </label>
    <div v-if="errorMessages.length">
      <p v-for="error in errorMessages" :key="error.id" class="text-default-input__error">
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputText",
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    isAverage: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: [Number, String],
      default: 5000,
    },
    isBordered: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
    },
    setValue: {
      type: Object,
      default() {
        return {};
      },
    },
    errorMessages: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onChange: {
      type: [Function, Boolean],
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.text-default-input {
  label {
    width: 100%;
  }
  input {
    width: 100%;
    background: #e5e5e7;
    border-radius: 5px;
    height: 36px;
    border: none;
    outline: none;
    font-size: 16px;
    padding: 17px 0 16px 16px;
    &::placeholder {
      color: 14px;
      padding: 10px 0px;
      font-size: 16px;
      color: $mainColor;
    }

    &:focus {
      background: #cdcdce;
    }
  }
  &--average {
    input {
      height: 36px;
    }
  }
  &--bordered {
    input {
      border: 1px solid #cdcdce;
      box-sizing: border-box;
    }
  }
  &--readonly {
    input {
      background: #bcbcbc;
    }
  }
  &__error {
    color: red;
    padding-left: 10px;
    padding-top: 10px;
    text-align: left;
    font-size: 14px;
  }

  &--disabled {
    /deep/input {
      background: #efefef;
    }
  }
}
/deep/input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
/deep/input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/deep/input[type="number"] {
  -moz-appearance: textfield;
}
/deep/input[type="number"]::-webkit-inner-spin-button {
  display: none;
}
</style>
