<template>
  <div class="fetch-spinner-modal">
    <div class="fetch-spinner-modal__spinner" />
  </div>
</template>
<script>
export default {
  name: "FetchSpinnerModal",
};
</script>

<style lang="scss" scoped>
.fetch-spinner-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 111501;
  background: rgba(229, 229, 229, 0.7);
  &__spinner {
    display: inline-block;
    position: absolute;
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    z-index: 111501;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 4px solid $coffee;
    border-left-color: transparent;
    background: transparent;
    animation: rotate 1s infinite linear;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
